import { useAuth } from "source/hooks";
import * as React from "react";
import LoadingBlock from "components/Loading/LoadingBlock";
import { useEffect } from "react";
import { useFetchUser } from "source/hoc/UserWrapper";
import { useRouter } from "next/router";

const LogIn = () => {
  const user = useFetchUser();
  const auth = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!user.loading && !user.user) {
      auth.login(
        "/",
      );
    }

    if (user.user) {
      router.push("/");
    }
  }, [user.loading, user.user, router]);

  return <LoadingBlock />;
};

export default LogIn;
